<script setup lang="ts">
import { useSurfaceStore } from '@@/pinia/surface'
import { useWindowSizeStore } from '@@/pinia/window_size'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    usageContext: 'postModal' | 'postView'
    attachmentCaption?: string
    /**
     * How the component should decide to show dark mode: Using browser or with custom isDark prop
     */
    darkThemeType?: 'browser' | 'custom'
    isDark?: boolean
  }>(),
  {
    attachmentCaption: '',
    darkThemeType: 'browser',
    isDark: false,
  },
)

defineEmits<{
  (name: 'click', event: MouseEvent): void
  (name: 'edit-start'): void
}>()

const { isPhone } = storeToRefs(useWindowSizeStore())
const { isStream, isWhiteboard } = storeToRefs(useSurfaceStore())

const xBigCaption = computed(() => {
  if (props.usageContext === 'postView') {
    return isStream.value
  } else if (props.usageContext === 'postModal') {
    return !isPhone.value
  }
  return false
})
</script>

<template>
  <p
    :class="[
      'm-0',
      {
        'text-whiteboard-body-extra-small': isWhiteboard,
        'text-body-small-posts': xBigCaption,
        'text-body-extra-small': !xBigCaption,
      },

      'font-sans',
      'whitespace-break-spaces',
      {
        'text-dark-text-200 focus:text-dark-text-100': darkThemeType === 'browser' || !isDark,
        'dark:text-light-text-200 dark:focus:text-light-text-100': darkThemeType === 'browser',
        'text-light-text-200 focus:text-light-text-100': darkThemeType === 'custom' && isDark,
      },
    ]"
    style="word-break: break-word"
    @click="$emit('click', $event)"
    @keydown.enter="$emit('click', $event)"
    @dblclick="$emit('edit-start')"
    v-text="attachmentCaption"
  />
</template>
